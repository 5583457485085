export const styles = {
  root: {
    overflow: 'hidden',
  },
  wrapper: {
    mt: '80px',
    overflowY: 'scroll',
    padding: '20px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100svh - 80px)',
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
