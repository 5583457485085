import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getIsAuthenticate, getIsInitialize } from '@bus/auth/selectors';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { authActions } from '@bus/auth/actions';

const Routers = () => {
  const dispatch = useDispatch();
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticate);

  useEffect(() => {
    dispatch(authActions.authenticate());
  }, [dispatch]);

  return isInitialised ? (
    <div>
      <BrowserRouter basename={'/'}>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            {isAuthenticated ? (
              <Route path={`/*`} element={<PrivateRoutes />} />
            ) : (
              <Route path={`/*`} element={<PublicRoutes />} />
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  ) : (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      Loading...
    </div>
  );
};

export default Routers;
