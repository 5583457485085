import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { privateRoutes } from '@pages/private/routes';
import NotFoundLayout from '../layouts/NotFoundLayout';
import { book } from '@routes/book';
import { useLocation } from 'react-router';
import AppLayout from '../layouts/AppLayout';
import { profileActions } from '@bus/profile/actions';

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(profileActions.fetchProfile());
  }, []);

  return (
    <Routes>
      {privateRoutes.map(({ path, Component, Layout, layoutProps }) => (
        <Route element={<Layout {...layoutProps} />} key={path}>
          <Route path={path} element={Component} />
        </Route>
      ))}
      {Object.values(book).some((item) => item === location.pathname) ? (
        <Route element={<AppLayout />}>
          <Route path={'/*'} element={<Navigate replace to={book.home} />} />
        </Route>
      ) : (
        <Route element={<NotFoundLayout />}>
          <Route path={'/*'} element={<>404</>} />
        </Route>
      )}
    </Routes>
  );
};

export default PrivateRoutes;
