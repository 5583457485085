/* eslint-disable prettier/prettier */
import layouts from '../../layouts/index';
import { commonRoutes } from '../../pages/common/routes';
import { book } from '../../routes/book';
import { AppRoute } from '../../setup/typedefs';
import React, { lazy } from 'react';

const SignInPage = lazy(()=> import('../../pages/public/SignInPage'));
export const publicRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.signIn}`,
      Component: <SignInPage />,
      Layout: layouts.AuthLayout,
      layoutProps: {},
    },
  ],
  ...commonRoutes,
];
