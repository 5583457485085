import AppLayout from './AppLayout';
import AuthLayout from './AuthLayout';
import NotFoundLayout from './NotFoundLayout';
const Layouts = {
  AppLayout,
  AuthLayout,
  NotFoundLayout,
};

export default Layouts;
