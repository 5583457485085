import { createAction } from '@reduxjs/toolkit';

import profileSlice from './slice';
import prepareActions from '../../helpers/prepareActions';

export const profileActions = {
  ...profileSlice.actions,
  fetchProfile: createAction('profile/fetchProfile'),
  updateProfile: createAction(
    'profile/updateProfile',
    prepareActions.movePromiseToMeta,
  ),
  finishOnboarding: createAction('profile/finishOnboarding', (payload: any) => {
    return { payload };
  }),
  fetchUserAlive: createAction('profile/fetchUserAlive'),
  deletePhoto: createAction('profile/deletePhoto'),
  deleteAccount: createAction(
    'profile/deleteAccount',
    prepareActions.withNavigate,
  ),
  fetchTokensSettings: createAction('profile/fetchTokensSettings'),
  // INJECT
};
