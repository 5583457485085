import {
  OnFormSubmitPayload,
  OnPayloadWithNavigation,
  OnPayloadWithPromiseNavigation,
} from '../core/FinalForm/typedefs';

const prepareActions = {
  movePromiseToMeta: ({ values, resolve, reject }: OnFormSubmitPayload) => {
    return {
      payload: values,
      meta: {
        resolve,
        reject,
      },
    };
  },
  withNavigate: ({ values, navigate }: OnPayloadWithNavigation) => {
    return {
      payload: values,
      meta: {
        navigate,
      },
    };
  },
  withPromiseNavigate: ({
    values,
    navigate,
    resolve,
    reject,
  }: OnPayloadWithPromiseNavigation) => {
    return {
      payload: values,
      meta: {
        navigate,
        resolve,
        reject,
      },
    };
  },
};

export default prepareActions;
