/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';

import layouts from '../../layouts/index';
import { commonRoutes } from '../../pages/common/routes';
import { book } from '../../routes/book';
import { AppRoute } from '../../setup/typedefs';
const HomePage = lazy(()=>import('../../pages/private/HomePage'));
const GamePage = lazy(()=>import('../../pages/private/GamePage'));

export const privateRoutes: AppRoute<any>[] = [
  ...[
    {
      path: `${book.home}`,
      Component: <HomePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    {
      path: `${book.game}`,
      Component: <GamePage />,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
  ],
  ...commonRoutes,
];
