// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  ProfileState,
  FillProfileActionPayload,
  ISubscriptionsDetail,
  ChangePhotoActionPayload,
  IScaleImage,
  FillTokensSettingsActionPayload,
} from './typedefs';

const initialState: ProfileState = {
  isFetching: false,
  isProfileFetched: false,
  profile: null,
  isUserInfoDirty: false,
  isUserPasswordDirty: false,
  scaleImage: null,
  amountOfUsers: 1,
  showProfileTooltip: false,
  isDatasetsOpen: false,
  currentDataset: null,
  scrollTo: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    setProfileFetched(state) {
      state.isProfileFetched = true;
    },
    setScaleImage(state, action: PayloadAction<IScaleImage | null>) {
      state.scaleImage = action.payload;
    },
    setScrollTo(state, action: PayloadAction<string | null>) {
      state.scrollTo = action.payload;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    showProfileTooltip(state) {
      state.showProfileTooltip = true;
    },
    closeProfileTooltip(state) {
      state.showProfileTooltip = false;
    },
    fillProfile(state, action: PayloadAction<FillProfileActionPayload>) {
      if (state.profile) {
        state.profile = { ...state.profile, ...action.payload };
      } else {
        state.profile = action.payload;
      }
    },
    setUserInfoDirty(state, action: PayloadAction<boolean>) {
      state.isUserInfoDirty = action.payload;
    },
    setAmountOfUsers(state, action: PayloadAction<number>) {
      state.amountOfUsers = action.payload;
    },
    setUserPasswordDirty(state, action: PayloadAction<boolean>) {
      state.isUserPasswordDirty = action.payload;
    },
    clearData(state) {
      state.profile = null;
      state.currentDataset = null;
      sessionStorage.clear();
      sessionStorage.clear();
    },
    updateSubscription(state, action: PayloadAction<ISubscriptionsDetail>) {
      if (state.profile) {
        state.profile = {
          ...state.profile,
          tokens: action.payload?.tokens ?? state.profile.tokens,
          subscription: {
            ...action.payload,
          },
        };
      }
    },
    cancelSubscription(state) {
      if (state?.profile?.subscription) {
        state.profile = {
          ...state.profile,
          subscription: {
            ...state.profile.subscription,
            paypal_status: 'CANCELLED',
          },
        };
      }
    },
    changePhoto(state, action: PayloadAction<ChangePhotoActionPayload>) {
      if (state.profile) {
        state.profile = {
          ...state.profile,
          ...action.payload,
        };
      }
    },
    setDatasetsOpen(state) {
      state.isDatasetsOpen = true;
    },
    setDatasetsClosed(state) {
      state.isDatasetsOpen = false;
    },
    fillTokensSettings(
      state,
      action: PayloadAction<FillTokensSettingsActionPayload>,
    ) {
      if (state.profile) {
        state.profile = { ...state.profile, ...action.payload };
      }
    },
    // INJECT
  },
});

export default profileSlice;
