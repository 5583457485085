import * as effects from 'redux-saga/effects';

import { authActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  authenticate,
  signIn,
} from './workers';

function* watchSignIn() {
  yield effects.takeEvery(authActions.signIn.type, signIn);
}
function* watchAuthenticate() {
  yield effects.takeEvery(authActions.authenticate.type, authenticate);
}
// WATCHERS
export function* watchAuth() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchAuthenticate),
    effects.call(watchSignIn),
    // INJECT
  ]);
}
