import React from 'react';
import './App.css';
import { useAppTheme } from '@themes/main';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import Routers from '@routes/index';
import { createStore } from '@setup/configureStore';

export const { store } = createStore();

function App() {
  const theme = useAppTheme();

  return theme ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Routers />
      </Provider>
    </ThemeProvider>
  ) : null;
}

export default App;
